// Generated by Framer (236a21b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {Dw5y6Xr8p: {hover: true}};

const cycleOrder = ["Dw5y6Xr8p"];

const serializationHash = "framer-hh2K1"

const variantClassNames = {Dw5y6Xr8p: "framer-v-1etask9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, width, ...props}) => { return {...props, Hnolp_Pjw: icon ?? props.Hnolp_Pjw ?? {src: "https://framerusercontent.com/images/BobHLeCspuU338ZIomD1xw.svg"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Hnolp_Pjw, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Dw5y6Xr8p", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1etask9", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Dw5y6Xr8p"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-b43d5421-21cd-4fc2-975a-9a99315a0fcc, rgb(19, 24, 57))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-6b63144b-5b88-4aed-bb3e-2c99fbafb55f, rgb(0, 5, 41))", borderBottomLeftRadius: 99, borderBottomRightRadius: 99, borderTopLeftRadius: 99, borderTopRightRadius: 99, ...style}} variants={{"Dw5y6Xr8p-hover": {backgroundColor: "var(--token-277926e4-3958-42f5-b868-00c6494f3799, rgb(0, 7, 61))"}}} {...addPropertyOverrides({"Dw5y6Xr8p-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", pixelHeight: 25, pixelWidth: 24, sizes: "24px", ...toResponsiveImage(Hnolp_Pjw)}} className={"framer-9ap6jr"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"qcb17P8zq"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hh2K1.framer-lp67w7, .framer-hh2K1 .framer-lp67w7 { display: block; }", ".framer-hh2K1.framer-1etask9 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 13px; position: relative; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-hh2K1 .framer-9ap6jr { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); overflow: hidden; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hh2K1.framer-1etask9 { gap: 0px; } .framer-hh2K1.framer-1etask9 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-hh2K1.framer-1etask9 > :first-child { margin-left: 0px; } .framer-hh2K1.framer-1etask9 > :last-child { margin-right: 0px; } }", ".framer-hh2K1[data-border=\"true\"]::after, .framer-hh2K1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"GdsHZIqfn":{"layout":["auto","auto"]}}}
 * @framerVariables {"Hnolp_Pjw":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framersn7v1oIuT: React.ComponentType<Props> = withCSS(Component, css, "framer-hh2K1") as typeof Component;
export default Framersn7v1oIuT;

Framersn7v1oIuT.displayName = "Buttons/ Icon Button";

Framersn7v1oIuT.defaultProps = {height: 50, width: 50};

addPropertyControls(Framersn7v1oIuT, {Hnolp_Pjw: {__defaultAssetReference: "data:framer/asset-reference,BobHLeCspuU338ZIomD1xw.svg?originalFilename=Icon+%2839%29.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(Framersn7v1oIuT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})